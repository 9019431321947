<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, email, numeric } from "vuelidate/lib/validators";
import Registry from "@/components/patient/registry.vue";
import MedicalRecordForm from "@/components/patient/medicalRecordForm.vue";
import ResultsGame from "@/components/patient/resultsGame.vue";
import API from "@/api.js";

//import codcfVer from "codice-fiscale-js";

/**
 * User list component
 */
export default {
  components: { Layout, PageHeader, Registry, MedicalRecordForm, ResultsGame},
  page: {
    title: "Dettagli cliente",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Scheda cliente",
      items: [
        {
          text: this.$auth.user().role === 'StoreManager' ? this.$auth.user().store.company_name : this.$auth.user().doctor.store.company_name,
        },
        {
          text: "Elenco clienti",
          active: true,
        },
      ],
      store: this.$auth.user().role === 'StoreManager' ? this.$auth.user().store : this.$auth.user().doctor.store,
      
      submitted:false,
      selected_tab_name:this.setactivetab(),

      user: null,
      medicalRecord: {
         domL_dx:false,
         domL_sx:false,
         domV_dx:false,
         domV_sx:false,
         nVedente_dx:false,
         nVedente_sx:false,
         sist:"T",
         rxl_sph_dx:"",
         rxl_sph_sx:"",
         rxl_cyl_dx:"",
         rxl_cyl_sx:"",
         rxl_ax_dx:"",
         rxl_ax_sx:"",
         rxl_add_dx:"",
         rxl_add_sx:"",
         rxl_vn_dx:"",
         rxl_vn_sx:"",
         rxl_vc_dx:"",
         rxl_vc_sx:"",
         rxl_vbin_dx:"",
         rxl_vbin_sx:"",
         rxl_dp_dx:"",
         rxl_dp_sx:"",
         rxi_sph_dx:"",
         rxi_sph_sx:"",
         rxi_cyl_dx:"",
         rxi_cyl_sx:"",
         rxi_ax_dx:"",
         rxi_ax_sx:"",
         rxi_add_dx:"",
         rxi_add_sx:"",
         rxi_vn_dx:"",
         rxi_vn_sx:"",
         rxi_vc_dx:"",
         rxi_vc_sx:"",
         rxi_vbin_dx:"",
         rxi_vbin_sx:"",
         rxi_dp_dx:"",
         rxi_dp_sx:"",
         rxv_sph_dx:"",
         rxv_sph_sx:"",
         rxv_cyl_dx:"",
         rxv_cyl_sx:"",
         rxv_ax_dx:"",
         rxv_ax_sx:"",
         rxv_add_dx:"",
         rxv_add_sx:"",
         rxv_vn_dx:"",
         rxv_vn_sx:"",
         rxv_vc_dx:"",
         rxv_vc_sx:"",
         rxv_vbin_dx:"",
         rxv_vbin_sx:"",
         rxv_dp_dx:"",
         rxv_dp_sx:"",
         forieL_dx:null,
         forieL_dir:"",
         forieV_dx:null,
         forieV_dir:"",
         },
         gamesReport:[],
    }
  },

  validations: {
    user: {
      name: { required },
      lastname: { required },
      codCF: { required},
      bDate: { required },
      sex: { required },
      email: { required, email},
      phone:{required, numeric},
      city:{required},
      province:{required},
    }
  },
  
  computed: {
    
  },

  mounted() {
    // Set the initial number of items   

    this.callRegistry();
    this.callMedicalRedord();
    this.callGamesReport();

   
  },

  methods: {

    setactivetab(){
      let temp=0;
      if (this.$route.params.tab==="main"){
        temp=0;
      }else if(this.$route.params.tab==="clinics"){
        temp=1;
      }else if(this.$route.params.tab==="exercies"){
        temp=2;
      }else{
        temp=0;
      }

      return temp;
    },

    async callRegistry(){
        let r = await this.axios.get(API.getPatient.path(this.$route.params.id))

          if(r.status==200) {
            this.user = r.data
          } else {
            console.error("ERROR: ",r.data)
          }
      
    },

    async callMedicalRedord(){

      let r = await this.axios.get(API.getPatientMedicalRecord.path(this.$route.params.id))
        if(r.status==200) {
          if(r.data.medicalRecord){
            this.medicalRecord = r.data.medicalRecord
          }
        } else {
          console.error("ERROR: ",r.data)
        }

    },
     async callGamesReport(){

        let r = await this.axios.get(API.getPatientPlayAction.path(this.$route.params.id))
        if(r.status==200) {
          this.gamesReport = r.data
        } else {
          console.error("ERROR: ",r.data)
        }
    },

  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
        <div class="card" v-if="user">
          <div class="card-body">
            <h3 class="">{{  user.name  }} {{ user.lastname }}</h3>
            <!-- <p class="card-title-desc">Anagrafica cliente</p> -->
            <b-tabs
              justified
              nav-class="nav-tabs-custom"
              content-class="p-3 text-muted"
              v-model="selected_tab_name"
            >
              <b-tab title="personalData" >
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Anagrafica</span>
                </template>
                <Registry v-bind:user="this.user" />
              </b-tab>
              <b-tab title="clinics">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Scheda Clinica</span>
                </template>
                <MedicalRecordForm v-bind:user="this.user" v-bind:medicalRecord="this.medicalRecord" />
              </b-tab>
              <b-tab title="exercise" >
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Storico esercizi</span>
                </template>
                  <ResultsGame v-bind:gamesReport="this.gamesReport" />

              </b-tab>
              <!--<b-tab title="Settings">
                <template v-slot:title >
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Impostazioni</span>
                </template>
                  <Settings />
              </b-tab>-->
            </b-tabs>
          </div>
        </div>
  </Layout>
</template>
