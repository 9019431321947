<script>
import API from '@/api';
import { required, email, numeric } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

//import codcfVer from "codice-fiscale-js";

export default {
  name: 'Registry',
  props: {
    user:{
      type: Object,
      default: () => ({}),
    }
  },

  data() {
    return {
      submitted:false, 
      currUser: this.$props.user,
    }
  },

  validations: {
    currUser: {
      name: { required },
      lastname: { required },
      codCF: { required},
      bDate: { required },
      sex: { required },
      email: { required, email},
      phone:{required, numeric},
      city:{required},
      province:{required},
    }
  },
  
  computed: {
             

  },

  mounted() {
    // Set the initial number of items
    console.log(this.currUser);
  },

  methods: {

    async handleSubmit(){
      
      this.submitted=true;

      this.$v.$touch();
      
      if (this.$v.$invalid) {
        return;

      } else {
        try {
            const r = await this.axios.put(API.updatePatient.path(this.currUser.id),this.currUser);
            if (r.status === 200) {
                this.responsemsg("Dati aggiornati", "success");
            } else {
                this.responsemsg("Operazione fallita", "error");
            }
        } catch (e) {
            this.responsemsg("Operazione fallita", "error");
        }
      }
    },

     responsemsg(msg,type) {
      Swal.fire({
        position: "center",
        icon: type,
        title: msg,
        showConfirmButton: false,
        timer: 1000,
      });
    },

    resetForm() {
        this.currUser = Object.assign({},this.$props.user);

    }

  },
  middleware: "authentication",
};
</script>

<template>
    
    <div>        
        <form @submit.prevent="handleSubmit">
            <div class="row">
                <div class="col-xl-6 col-12">
                    <div class="mb-3">
                    <label for="name" class="form-label">Nome</label>
                    <input
                        id="name"
                        v-model="currUser.name"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && this.$v.currUser.name.$error  }"
                        placeholder="Inserisci Nome"
                    /> 
                    <div v-if="submitted && this.$v.currUser.name.$error"  class="col-12 invalid-feedback d-block">
                        <span v-if="!this.$v.currUser.name.required">Campo obbligatorio</span>
                    </div> 
                    </div>  
                </div>
                <div class="col-xl-6 col-12">
                    <div class="mb-3">
                    <label class="control-label form-label">Cognome</label>
                    <input
                        id="lastname"
                        v-model="currUser.lastname"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && this.$v.currUser.lastname.$error }"
                        placeholder="Inserisci cognome"
                    />
                    <div v-if="submitted && this.$v.currUser.lastname.$error"  class="col-12 invalid-feedback d-block">
                        <span v-if="!this.$v.currUser.lastname.required">Campo obbligatorio</span>
                    </div> 
                    </div>
                </div>
                <div class="col-xl-4 col-12">
                    <div class="mb-3">
                    <label class="control-label form-label">Data</label>
                    <b-form-input
                        id="bDate"
                        v-model="currUser.bDate"
                        type="date"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && this.$v.currUser.bDate.$error }"
                        placeholder="Inserire data"   
                    ></b-form-input>
                    <div v-if="submitted && this.$v.currUser.bDate.$error"  class="col-12 invalid-feedback d-block">
                        <span v-if="!this.$v.currUser.bDate.required">Campo obbligatorio</span>
                    </div>
                    </div> 
                </div>
                <div class="col-8">
                    <div class="mb-3">
                    <label class="control-label form-label">Codice Fiscale</label>
                    <input
                        id="codCF"
                        v-model="currUser.codCF"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && this.$v.currUser.codCF.$error }"
                        placeholder="Inserisci Codice Fiscale"
                    />
                    <div v-if="submitted && this.$v.currUser.codCF.$error"  class="col-12 invalid-feedback d-block">
                        <span v-if="!this.$v.currUser.codCF.required">Campo obbligatorio</span>
                    </div>
                    </div> 
                </div>
                <div class="col-xl-6 col-12">
                    <div class="mb-3">
                    <label class="control-label form-label">e-mail</label>
                    <input
                        id="email"
                        v-model="currUser.email"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && this.$v.currUser.email.$error }"
                        placeholder="Inserisci e-mail"
                    /> 
                    <div v-if="submitted && this.$v.currUser.email.$error"  class="col-12 invalid-feedback d-block">
                        <span v-if="!this.$v.currUser.email.required">Campo obbligatorio</span>
                        <span v-if="!this.$v.currUser.email.email">Indirizzo mail non valido</span>
                    </div>
                    </div>
                </div>
                <div class="col-xl-6 col-12">
                    <div class="mb-3">
                    <label class="control-label form-label">Telefono</label>
                    <input
                        id="phone"
                        v-model="currUser.phone"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && this.$v.currUser.phone.$error }"
                        placeholder="Iserire telefono"
                    />
                    <div v-if="submitted && this.$v.currUser.phone.$error"  class="col-12 invalid-feedback d-block">
                        <span v-if="!this.$v.currUser.phone.required">Campo obbligatorio</span>
                        <span v-if="!this.$v.currUser.phone.numeric">Valori non ammessi</span>
                    </div>
                    </div>  
                </div>
                <div class="col-xl-6 col-12">
                    <div class="mb-3">
                    <label class="control-label form-label">Citta</label>
                    <input
                        id="city"
                        v-model="currUser.city"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && this.$v.currUser.city.$error }"
                        placeholder="Inserire un paese"
                    />
                    <div v-if="submitted && this.$v.currUser.city.$error"  class="col-12 invalid-feedback d-block">
                        <span v-if="!this.$v.currUser.city.required">Campo obbligatorio</span>
                    </div>
                    </div>
                </div>
                <div class="col-xl-6 col-12">
                    <div class="mb-3">
                    <label class="control-label form-label">Provincia</label>
                    <input
                        id="province"
                        v-model="currUser.province"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && this.$v.currUser.province.$error }"
                        placeholder="Inserire un paese"
                    />
                    <div v-if="submitted && this.$v.currUser.province.$error"  class="col-12 invalid-feedback d-block">
                        <span v-if="!this.$v.currUser.province.required">Campo obbligatorio</span>
                    </div>
                    </div>
                </div>
                <div class="col-xl-6 col-12">
                    <div class="mb-3">
                        <label class="control-label form-label">Sesso</label>
                        <div class="row">
                            <div class="col-6">
                                <b-form-radio
                                v-model="currUser.sex"
                                class="mb-3"
                                value="M"
                                plain
                                >Uomo</b-form-radio
                                >
                            </div>
                            <div class="col-6">
                                <b-form-radio
                                v-model="currUser.sex"
                                class="custom-radio mb-3"
                                value="F"
                                plain
                                >Donna</b-form-radio
                                >   
                            </div>
                        </div>
                        <div v-if="submitted && this.$v.currUser.sex.$error"  class="col-12 invalid-feedback d-block">
                            <span v-if="!this.$v.currUser.sex.required">Campo obbligatorio</span>
                        </div>
                    </div>
                </div> 
            </div>
            <div class="row ">
                <div class="p-3 col-12 form-group d-flex justify-content-start">
                    <b-button  type="submit" class="ms-1" variant="primary" 
                        >Salva le modifiche</b-button
                    > &nbsp; &nbsp;
                    <b-button  type="reset" @click.prevent="resetForm()" class="ms-1" variant="outline-primary" 
                        >Annulla</b-button
                    >
                </div>

            </div>     
        </form>
    </div>
</template>
