<script>
import API from '@/api';
import Swal from "sweetalert2";

import { numeric,decimal, between } from "vuelidate/lib/validators";

//import codcfVer from "codice-fiscale-js";
import ECharts from "vue-echarts";
import "echarts/lib/chart/gauge";  
  

import {
  axisChart_sx,
  axisChart_dx,
} from "./data";




export default {
  name: 'MedicalRecordForm',
  props: {
   medicalRecord:{
      type: Object,
        default: () => {
            return {};

        },
    
    },
    user:{
       type: Object, 
       default: () => ({}),
    },
    
  },

  components: {
    "v-chart": ECharts,
  },

  data() {
    return {
      submitted:false, 
      axisChart_sx: axisChart_sx,
      axisChart_dx: axisChart_dx,
      user_bkup: this.$props.user,
      medicalRecord_bkup: this.$props.medicalRecord,
    }
  },

  


  validations: {
    medicalRecord: {
       

        rxl_sph_dx:{decimal, between: between(-50, +50)},
        rxl_sph_sx:{decimal, between: between(-50, +50)},
        rxl_cyl_dx:{decimal, between: between(-10, +10)},
        rxl_cyl_sx:{decimal, between: between(-10, +10)},
        rxl_ax_dx:{decimal, between: between(0, 180)},
        rxl_ax_sx:{decimal, between: between(0, 180)},
        rxl_add_dx:{decimal, between: between(0, 5)},
        rxl_add_sx:{decimal, between: between(0, 5)},
        rxl_vn_dx:{decimal, between: between(0, 20)},
        rxl_vn_sx:{decimal, between: between(0, 20)},
        rxl_vc_dx:{decimal, between: between(0, 20)},
        rxl_vc_sx:{decimal, between: between(0, 20)},
        rxl_vbin_dx:{decimal, between: between(0, 20)},
        rxl_vbin_sx:{decimal, between: between(0, 20)},
        rxl_dp_dx:{decimal, between: between(0, 100)},
        rxl_dp_sx:{decimal, between: between(0, 100)},
        rxi_sph_dx:{decimal, between: between(-50, +50)},
        rxi_sph_sx:{decimal, between: between(-50, +50)},
        rxi_cyl_dx:{decimal, between: between(-10, +10)},
        rxi_cyl_sx:{decimal, between: between(-10, +10)},
        rxi_ax_dx:{decimal, between: between(0, 180)},
        rxi_ax_sx:{decimal, between: between(0, 180)},
        rxi_add_dx:{decimal, between: between(0, 5)},
        rxi_add_sx:{decimal, between: between(0, 5)},
        rxi_vn_dx:{decimal, between: between(0, 20)},
        rxi_vn_sx:{decimal, between: between(0, 20)},
        rxi_vc_dx:{decimal, between: between(0, 20)},
        rxi_vc_sx:{decimal, between: between(0, 20)},
        rxi_vbin_dx:{decimal, between: between(0, 20)},
        rxi_vbin_sx:{decimal, between: between(0, 20)},
        rxi_dp_dx:{decimal, between: between(0, 100)},
        rxi_dp_sx:{decimal, between: between(0, 100)},
        rxv_sph_dx:{decimal, between: between(-50, +50)},
        rxv_sph_sx:{decimal, between: between(-50, +50)},
        rxv_cyl_dx:{decimal, between: between(-10, +10)},
        rxv_cyl_sx:{decimal, between: between(-10, +10)},
        rxv_ax_dx:{decimal, between: between(0, 180)},
        rxv_ax_sx:{decimal, between: between(0, 180)},
        rxv_add_dx:{decimal, between: between(0, 5)},
        rxv_add_sx:{decimal, between: between(0, 5)},
        rxv_vn_dx:{decimal, between: between(0, 20)},
        rxv_vn_sx:{decimal, between: between(0, 20)},
        rxv_vc_dx:{decimal, between: between(0, 20)},
        rxv_vc_sx:{decimal, between: between(0, 20)},
        rxv_vbin_dx:{decimal, between: between(0, 20)},
        rxv_vbin_sx:{decimal, between: between(0, 20)},
        rxv_dp_dx:{decimal, between: between(0, 100)},
        rxv_dp_sx:{decimal, between: between(0, 100)},
        forieL_dx:{numeric, between: between(0, 50)},
        forieV_dx:{numeric, between: between(0, 50)},

        
    }
  },
  
  computed: {
   
  },

  mounted() {
    // code for http request.
    this.axisChart_sx.series[0].data[0].value=this.$props.medicalRecord.rxl_ax_sx;
    this.axisChart_dx.series[0].data[0].value=this.$props.medicalRecord.rxl_ax_dx;

  },

  methods: {

    //Verifica unicità dominanza
    checkDom(value) {
        if(value=='LR'){
            if(this.medicalRecord.domL_dx==true && this.medicalRecord.domL_dx==true){
                this.medicalRecord.domL_sx=false
            }
        }else if(value=='LL'){
            if(this.medicalRecord.domL_dx==true && this.medicalRecord.domL_dx==true){
                this.medicalRecord.domL_dx=false
            }
        }
        if(value=='VR'){
            if(this.medicalRecord.domV_dx==true && this.medicalRecord.domV_dx==true){
                this.medicalRecord.domV_sx=false
            }
        }else if(value=='VL'){
            if(this.medicalRecord.domV_dx==true && this.medicalRecord.domV_dx==true){
                this.medicalRecord.domV_dx=false
            }

        }
    },
    axUpdate(eye) {

        if(eye=='sx'){
            this.axisChart_sx.series[0].data[0].value=this.$props.medicalRecord.rxl_ax_sx;
        }else if(eye=='dx'){
            this.axisChart_dx.series[0].data[0].value=this.$props.medicalRecord.rxl_ax_dx;
        }
    },
    addCompile(eye) {

        if(eye=='sx' && (this.$props.medicalRecord.rxl_sph_sx!=null || this.$props.medicalRecord.rxl_sph_sx!='')){
            this.$props.medicalRecord.rxv_sph_sx=this.$props.medicalRecord.rxl_sph_sx-this.$props.medicalRecord.rxl_add_sx
        }else if(eye=='dx' && (this.$props.medicalRecord.rxl_sph_dx!=null || this.$props.medicalRecord.rxl_sph_dx!='')){
            this.$props.medicalRecord.rxv_sph_dx=this.$props.medicalRecord.rxl_sph_dx-this.$props.medicalRecord.rxl_add_dx
        }
        
    },
  async handleSubmit(){
      this.submitted=true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        console.log("kkk ",this.$props.medicalRecord.id);
        if (this.$props.medicalRecord.id) {
            console.log("PUT");
            const current = new Date();
            this.medicalRecord.updateDate=`${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
            let r = await this.axios.put(API.updateMedicalRecord.path(this.medicalRecord.id),this.medicalRecord);
            if (r.status === 204 || r.status === 201 || r.status === 200) {
                this.responsemsg("Scheda paziente aggiornata", "success");
                this.reload();
            } else {
                this.responsemsg("Operazione fallita","error");
            }
        } else {
            this.medicalRecord.patientid=this.user;
            let r = await this.axios.post(API.postMedicalRecord.path(),this.medicalRecord);
            if (r.status === 201 ) {
                this.responsemsg("Scheda paziente inserita", "success");
                this.reload();
            } else {
                this.responsemsg("Operazione fallita","error");
            }
        }
      }
    },


     responsemsg(msg,type) {
      Swal.fire({
        position: "center",
        icon: type,
        title: msg,
        showConfirmButton: false,
        timer: 1000,
      });
    },
    resetForm() {
        this.user = Object.assign({},this.user_bkup);
        this.medicalRecord = Object.assign({}, this.medicalRecord_bkup);

    },
//reload data of mediacal record
    async reload(){
        let r = await this.axios(API.getMedicalRecord.path(this.$route.params.id));
        if (r.status === 200) {
            this.medicalRecord = r.data.medicalRecord;
        }
    },
  },
  watch: {
      medicalRecord(newRecord) {
           this.axisChart_sx.series[0].data[0].value=newRecord.rxl_ax_sx;
           this.axisChart_dx.series[0].data[0].value=newRecord.rxl_ax_dx;
      }
  },
  middleware: "authentication",
};
</script>

<style>
.medicalr-class .form-control{
    padding-top:0.25rem;
    padding-bottom:0.25rem;
    padding-left:0;
    padding-right:0;
    text-align: center;
}

.medicalr-class .was-validated .form-control:invalid, .form-control.is-invalid{
    background-image:none;
}
</style>


<template>
    
    <div class="medicalr-class">        
        <form @submit.prevent="handleSubmit">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row ">
                        <!-- Occhio destro -->
                        <div class="col-6 bg-light">
                            <!-- basic info -->
                            <div class="row bg-primary py-2">
                                <div class="col-12">
                                    <h5 class="text-white">Occhio Sinistro</h5>
                                </div>
                            </div>
                        <div class="row mt-2">
                            <div class="col-3">
                                    <b-form-checkbox
                                        v-model="medicalRecord.nVedente_sx"
                                        class="mb-3"
                                        checked
                                        plain
                                        >Non vedente.</b-form-checkbox
                                    >
                                </div>
                                <div class="col-3">
                                    <b-form-checkbox
                                        v-model="medicalRecord.domL_sx"
                                        class="mb-3"
                                        checked
                                        @change="checkDom('LL')"
                                        plain
                                        >Dominante L.</b-form-checkbox
                                    >
                                </div>
                                <div class="col-3">
                                    <b-form-checkbox
                                        v-model="medicalRecord.domV_sx"
                                        class="mb-3"
                                        checked
                                        @change="checkDom('VL')"
                                        plain
                                        >Dominante V.</b-form-checkbox
                                    >
                                </div>
                                
                            </div>
                            <!-- end basic info -->
                        </div>
                        <div class="col-6 bg-light">
                            <div class="row bg-primary py-2">
                                <div class="col-12">
                                    <h5 class="text-white">Occhio Destro</h5>
                                </div>
                            </div>
                        <div class="row mt-2 text-right">
                            <div class="col-3 ">
                                    <b-form-checkbox
                                        v-model="medicalRecord.nVedente_dx"
                                        class="mb-3"
                                        checked
                                        plain
                                        >Non vedente.</b-form-checkbox
                                    >
                            </div>
                            <div class="col-3">
                                <b-form-checkbox
                                    v-model="medicalRecord.domL_dx"
                                    class="mb-3"
                                    @change="checkDom('LR')"
                                    checked
                                    plain
                                    >Dominante L.</b-form-checkbox
                                >
                            </div>
                            <div class="col-3">
                                <b-form-checkbox
                                    v-model="medicalRecord.domV_dx"
                                    class="mb-3"
                                    checked
                                    @change="checkDom('VR')"
                                    plain
                                    >Dominante V.</b-form-checkbox
                                >
                            </div>
                            </div>
                        </div>
                    </div>
                    <!-- Grafici -->
                    <div class="row bg-light" Style="max-height:250px">
                        <div class="col-5 align-self-center">
                            <v-chart style="  width: auto!important;" :options="axisChart_sx" autoresize />
                        </div>
                        <div class="col-1">
                        </div>
                        <div class="col-5">
                            <v-chart style=" width: auto!important; " :options="axisChart_dx" autoresize />
                        </div>
                    </div>
                    <!-- Refrazioni -->
                    <div class="row">
                        <div class="col-12">
                            <div class="row bg-primary py-2" >
                                <div class="col-11">
                                    <h5 class="text-white">
                                        Refrazioni
                                    </h5>
                                </div>
                                <div class="col-1">
                                    <b-button variant="light" v-b-toggle.refractions>
                                        Espandi
                                    </b-button>
                                </div>
                            </div>
                            <!-- <hr> -->
                        </div>
                        <b-collapse id="refractions" visible class="col-12 py-4 px-2 bg-light">
                            <!-- R. lontano -->
                            <div class="row">
                                <!-- Refrazione Lon. occhio sx -->
                                <div class="col-5">
                                    <div class="row">
                                        <div class="col-2">
                                            <label class="control-label form-label">Sph</label>
                                            <input
                                                id="rxl_sph_sx"
                                                v-model="medicalRecord.rxl_sph_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxl_sph_sx.$error  }"
                                                placeholder=""
                                            />   
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Cyl</label>
                                            <input
                                                id="rxl_cyl_sx"
                                                v-model="medicalRecord.rxl_cyl_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxl_cyl_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Ax</label>
                                            <input
                                                id="rxl_ax_sx"
                                                v-model="medicalRecord.rxl_ax_sx"
                                                type="text"
                                                @change="axUpdate('sx')"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxl_ax_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Add</label>
                                            <input
                                                id="rxl_add_sx"
                                                v-model="medicalRecord.rxl_add_sx"
                                                type="text"
                                                class="form-control"
                                                @change="addCompile('sx')"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxl_add_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Dp</label>
                                            <input
                                                id="rxl_dp_sx"
                                                v-model="medicalRecord.rxl_dp_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxl_dp_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div v-if="submitted && (this.$v.medicalRecord.rxl_sph_sx.$error
                                                        || this.$v.medicalRecord.rxl_cyl_sx.$error
                                                        || this.$v.medicalRecord.rxl_ax_sx.$error
                                                        || this.$v.medicalRecord.rxl_add_sx.$error
                                                        || this.$v.medicalRecord.rxl_dp_sx.$error)"  class="col-12 invalid-feedback d-block">
                                            <span v-if="!this.$v.medicalRecord.rxl_sph_sx.decimal || !this.$v.medicalRecord.rxl_sph_sx.between">Per lo sfero sono ammessi solo valori tra -50 e +50<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxl_cyl_sx.decimal || !this.$v.medicalRecord.rxl_cyl_sx.between">Per il cilindro sono ammessi solo valori tra -10 e +10<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxl_ax_sx.decimal || !this.$v.medicalRecord.rxl_ax_sx.between">Per l'asse sono ammessi solo valori tra 0 e 180<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxl_add_sx.decimal || !this.$v.medicalRecord.rxl_add_sx.between">Per l'addizione sono ammessi solo valori tra 0 e 5<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxl_dp_sx.decimal || !this.$v.medicalRecord.rxl_dp_sx.between">Per la distanza sono ammessi solo valori tra 0 e 100<br></span>
                                        </div> 
                                    </div>
                                </div>
                                <!-- fine Refrazione Lon. occhio sx -->
                                <div class="col-2">
                                    <p class="mt-5">Da Lontano</p>
                                </div>
                                <!-- Refrazione Lon. occhio destro -->
                                <div class="col-5">
                                    <div class="row">
                                        <div class="col-2">
                                            <label class="control-label form-label">Sph</label>
                                            <input
                                                id="rxl_sph_dx"
                                                v-model="medicalRecord.rxl_sph_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxl_sph_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Cyl</label>
                                            <input
                                                id="rxl_cyl_dx"
                                                v-model="medicalRecord.rxl_cyl_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxl_cyl_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Ax</label>
                                            <input
                                                id="rxl_ax_dx"
                                                v-model="medicalRecord.rxl_ax_dx"
                                                type="text"
                                                @change="axUpdate('dx')"
                                                class="form-control"
                                                
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxl_ax_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Add</label>
                                            <input
                                                id="rxl_add_dx"
                                                v-model="medicalRecord.rxl_add_dx"
                                                @change="addCompile('dx')"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxl_add_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Dp</label>
                                            <input
                                                id="rxl_dp_dx"
                                                v-model="medicalRecord.rxl_dp_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxl_dp_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div v-if="submitted && (this.$v.medicalRecord.rxl_sph_dx.$error
                                                        || this.$v.medicalRecord.rxl_cyl_dx.$error
                                                        || this.$v.medicalRecord.rxl_ax_dx.$error
                                                        || this.$v.medicalRecord.rxl_add_dx.$error
                                                        || this.$v.medicalRecord.rxl_dp_dx.$error)"  class="col-12 invalid-feedback d-block">
                                            <span v-if="!this.$v.medicalRecord.rxl_sph_dx.decimal || !this.$v.medicalRecord.rxl_sph_dx.between">Per lo sfero sono ammessi solo valori tra -50 e +50<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxl_cyl_dx.decimal || !this.$v.medicalRecord.rxl_cyl_dx.between">Per il cilindro sono ammessi solo valori tra -10 e +10<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxl_ax_dx.decimal || !this.$v.medicalRecord.rxl_ax_dx.between">Per l'asse sono ammessi solo valori tra 0 e 180<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxl_add_dx.decimal || !this.$v.medicalRecord.rxl_add_dx.between">Per l'addizione sono ammessi solo valori tra 0 e 5<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxl_dp_dx.decimal || !this.$v.medicalRecord.rxl_dp_dx.between">Per la distanza sono ammessi solo valori tra 0 e 100<br></span>
                                        </div>
                                    </div>
                                </div>
                                <!-- fine Refrazione Lon. occhio dx -->
                            </div>
                            <!-- R. intermedia -->
                            <div class="row">
                                <!-- Refrazione Int. occhio sx -->
                                <div class="col-5">       
                                    <div class="row">
                                        <div class="col-2">
                                            <label class="control-label form-label">Sph</label>
                                            <input
                                                id="rxi_sph_sx"
                                                v-model="medicalRecord.rxi_sph_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxi_sph_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Cyl</label>
                                            <input
                                                id="rxi_cyl_sx"
                                                v-model="medicalRecord.rxi_cyl_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxi_cyl_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Ax</label>
                                            <input
                                                id="rxi_ax_sx"
                                                v-model="medicalRecord.rxi_ax_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxi_ax_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Add</label>
                                            <input
                                                id="rxi_add_sx"
                                                v-model="medicalRecord.rxi_add_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxi_add_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Dp</label>
                                            <input
                                                id="rxi_dp_sx"
                                                v-model="medicalRecord.rxi_dp_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxi_dp_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div> 
                                        <div v-if="submitted && (this.$v.medicalRecord.rxi_sph_sx.$error
                                                        || this.$v.medicalRecord.rxi_cyl_sx.$error
                                                        || this.$v.medicalRecord.rxi_ax_sx.$error
                                                        || this.$v.medicalRecord.rxi_add_sx.$error
                                                        || this.$v.medicalRecord.rxi_dp_sx.$error)"  class="col-12 invalid-feedback d-block">
                                            <span v-if="!this.$v.medicalRecord.rxi_sph_sx.decimal || !this.$v.medicalRecord.rxi_sph_sx.between">Per lo sfero sono ammessi solo valori tra -50 e +50<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxi_cyl_sx.decimal || !this.$v.medicalRecord.rxi_cyl_sx.between">Per il cilindro sono ammessi solo valori tra -10 e +10<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxi_ax_sx.decimal || !this.$v.medicalRecord.rxi_ax_sx.between">Per l'asse sono ammessi solo valori tra 0 e 180<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxi_add_sx.decimal || !this.$v.medicalRecord.rxi_add_sx.between">Per l'addizione sono ammessi solo valori tra 0 e 5<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxi_dp_sx.decimal || !this.$v.medicalRecord.rxi_dp_sx.between">Per la distanza sono ammessi solo valori tra 0 e 100<br></span>
                                        </div>
                                    </div>                          
                                </div>
                                <!-- Fine Refrazione Int. occhio sx -->
                                <div class="col-2">
                                    <p class="mt-5">Intermedio</p>
                                </div>
                                <!-- Refrazione Int. occhio dx -->
                                <div class="col-5">
                                    <div class="row">
                                        <div class="col-2">
                                            <label class="control-label form-label">Sph</label>
                                            <input
                                                id="rxi_sph_dx"
                                                v-model="medicalRecord.rxi_sph_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxi_sph_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Cyl</label>
                                            <input
                                                id="rxi_cyl_dx"
                                                v-model="medicalRecord.rxi_cyl_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxi_cyl_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Ax</label>
                                            <input
                                                id="rxi_ax_dx"
                                                v-model="medicalRecord.rxi_ax_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxi_ax_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Add</label>
                                            <input
                                                id="rxi_add_dx"
                                                v-model="medicalRecord.rxi_add_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxi_add_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Dp</label>
                                            <input
                                                id="rxi_dp_dx"
                                                v-model="medicalRecord.rxi_dp_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxi_dp_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div> 
                                    
                                        <div v-if="submitted && (this.$v.medicalRecord.rxi_sph_dx.$error
                                                            || this.$v.medicalRecord.rxi_cyl_dx.$error
                                                            || this.$v.medicalRecord.rxi_ax_dx.$error
                                                            || this.$v.medicalRecord.rxi_add_dx.$error
                                                            || this.$v.medicalRecord.rxi_dp_dx.$error)"  class="col-12 invalid-feedback d-block">
                                            <span v-if="!this.$v.medicalRecord.rxi_sph_dx.decimal || !this.$v.medicalRecord.rxi_sph_dx.between">Per lo sfero sono ammessi solo valori tra -50 e +50<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxi_cyl_dx.decimal || !this.$v.medicalRecord.rxi_cyl_dx.between">Per il cilindro sono ammessi solo valori tra -10 e +10<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxi_ax_dx.decimal || !this.$v.medicalRecord.rxi_ax_dx.between">Per l'asse sono ammessi solo valori tra 0 e 180<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxi_add_dx.decimal || !this.$v.medicalRecord.rxi_add_dx.between">Per l'addizione sono ammessi solo valori tra 0 e 5<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxi_dp_dx.decimal || !this.$v.medicalRecord.rxi_dp_dx.between">Per la distanza sono ammessi solo valori tra 0 e 100<br></span>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine Refrazione Int. occhio dx -->
                            </div>
                            <!-- R. vicino -->
                            <div class="row">
                                <!-- Refrazione Vic. occhio sx -->
                                <div class="col-5">
                                    <div class="row">
                                        <div class="col-2">
                                            <label class="control-label form-label">Sph</label>
                                            <input
                                                id="rxv_sph_sx"
                                                v-model="medicalRecord.rxv_sph_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_sph_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Cyl</label>
                                            <input
                                                id="rxv_cyl_sx"
                                                v-model="medicalRecord.rxv_cyl_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_cyl_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Ax</label>
                                            <input
                                                id="rxv_ax_sx"
                                                v-model="medicalRecord.rxv_ax_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_ax_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Add</label>
                                            <input
                                                id="rxv_add_sx"
                                                v-model="medicalRecord.rxv_add_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_add_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Dp</label>
                                            <input
                                                id="rxv_dp_sx"
                                                v-model="medicalRecord.rxv_dp_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_dp_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div> 
                                        <div v-if="submitted && (this.$v.medicalRecord.rxv_sph_sx.$error
                                                            || this.$v.medicalRecord.rxv_cyl_sx.$error
                                                            || this.$v.medicalRecord.rxv_ax_sx.$error
                                                            || this.$v.medicalRecord.rxv_add_sx.$error
                                                            || this.$v.medicalRecord.rxv_dp_sx.$error)"  class="col-12 invalid-feedback d-block">
                                            <span v-if="!this.$v.medicalRecord.rxv_sph_sx.decimal || !this.$v.medicalRecord.rxv_sph_sx.between">Per lo sfero sono ammessi solo valori tra -50 e +50<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxv_cyl_sx.decimal || !this.$v.medicalRecord.rxv_cyl_sx.between">Per il cilindro sono ammessi solo valori tra -10 e +10<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxv_ax_sx.decimal || !this.$v.medicalRecord.rxv_ax_sx.between">Per l'asse sono ammessi solo valori tra 0 e 180<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxv_add_sx.decimal || !this.$v.medicalRecord.rxv_add_sx.between">Per l'addizione sono ammessi solo valori tra 0 e 5<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxv_dp_sx.decimal || !this.$v.medicalRecord.rxv_dp_sx.between">Per la distanza sono ammessi solo valori tra 0 e 100<br></span>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine Refrazione Vic. occhio sx -->
                                <div class="col-2">
                                    <p class="mt-5">Da vicino</p>
                                </div>
                                <!-- Refrazione Vic. occhio dx -->
                                <div class="col-5">
                                    <div class="row">
                                        <div class="col-2">
                                            <label class="control-label form-label">Sph</label>
                                            <input
                                                id="rxv_sph_dx"
                                                v-model="medicalRecord.rxv_sph_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_sph_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Cyl</label>
                                            <input
                                                id="rxv_cyl_dx"
                                                v-model="medicalRecord.rxv_cyl_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_cyl_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Ax</label>
                                            <input
                                                id="rxv_ax_dx"
                                                v-model="medicalRecord.rxv_ax_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_ax_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Add</label>
                                            <input
                                                id="rxv_add_dx"
                                                v-model="medicalRecord.rxv_add_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_add_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">Dp</label>
                                            <input
                                                id="rxv_dp_dx"
                                                v-model="medicalRecord.rxv_dp_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_dp_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div> 
                                        <div v-if="submitted && (this.$v.medicalRecord.rxv_sph_sx.$error
                                                            || this.$v.medicalRecord.rxv_cyl_dx.$error
                                                            || this.$v.medicalRecord.rxv_ax_dx.$error
                                                            || this.$v.medicalRecord.rxv_add_dx.$error
                                                            || this.$v.medicalRecord.rxv_dp_dx.$error)"  class="col-12 invalid-feedback d-block">
                                            <span v-if="!this.$v.medicalRecord.rxv_sph_dx.decimal || !this.$v.medicalRecord.rxv_sph_dx.between">Per lo sfero sono ammessi solo valori tra -50 e +50<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxv_cyl_dx.decimal || !this.$v.medicalRecord.rxv_cyl_dx.between">Per il cilindro sono ammessi solo valori tra -10 e +10<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxv_ax_dx.decimal || !this.$v.medicalRecord.rxv_ax_dx.between">Per l'asse sono ammessi solo valori tra 0 e 180<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxv_add_dx.decimal || !this.$v.medicalRecord.rxv_add_dx.between">Per l'addizione sono ammessi solo valori tra 0 e 5<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxv_dp_dx.decimal || !this.$v.medicalRecord.rxv_dp_dx.between">Per la distanza sono ammessi solo valori tra 0 e 100<br></span>
                                        </div>
                                    </div>                         
                                </div>
                                <!-- Fine Refrazione Vic. occhio dx -->
                            </div>
                        </b-collapse>
                    </div>
                    <!-- fine refrazione -->

                    <!-- Visius -->
                    <div class="row">
                        <div class="col-12">
                            <div class="row bg-primary py-2">
                                <div class="col-11">
                                    <h5 class="text-white">
                                        Visius
                                    </h5>
                                </div>
                                <div class="col-1">
                                    <b-button variant="light" v-b-toggle.visius>
                                        Espandi
                                    </b-button>
                                </div>
                            </div>
                            <!-- <hr /> -->
                        </div>
                        <b-collapse id="visius" visible class="col-12 collapse py-4 bg-light">
                            <!-- V. lontano -->
                            <div class="row">
                                <!-- Visius Lon. occhio sx -->
                                <div class="col-5 ">
                                    <div class="row">
                                        <div class="col-4"></div>
                                        <div class="col-2">
                                            <label class="control-label form-label">V/n</label>
                                            <input
                                                id="rxl_vn_sx"
                                                v-model="medicalRecord.rxl_vn_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxl_vn_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">V/c</label>
                                            <input
                                                id="rxl_vc_sx"
                                                v-model="medicalRecord.rxl_vc_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxl_vc_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">V/bin</label>
                                            <input
                                                id="rxl_vbin_sx"
                                                v-model="medicalRecord.rxl_vbin_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxl_vbin_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div v-if="submitted && (this.$v.medicalRecord.rxl_vn_sx.$error
                                                        || this.$v.medicalRecord.rxl_vc_sx.$error
                                                        || this.$v.medicalRecord.rxl_vbin_sx.$error)"  class="col-12 invalid-feedback d-block">
                                            <span v-if="!this.$v.medicalRecord.rxl_vn_sx.decimal || !this.$v.medicalRecord.rxl_vn_sx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxl_vc_sx.decimal || !this.$v.medicalRecord.rxl_vc_sx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxl_vbin_sx.decimal || !this.$v.medicalRecord.rxl_vbin_sx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                        </div> 
                                    </div>
                                </div>
                                <!-- fine Visius Lon. occhio sx -->
                                <div class="col-2">
                                    <p class="mt-5">Da Lontano</p>
                                </div>
                                <!-- Visius Lon. occhio dx -->
                                <div class="col-5">
                                    <div class="row">
                                        <div class="col-2">
                                            <label class="control-label form-label">V/n</label>
                                            <input
                                                id="rxl_vn_dx"
                                                v-model="medicalRecord.rxl_vn_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxl_vn_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">V/c</label>
                                            <input
                                                id="rxl_vc_dx"
                                                v-model="medicalRecord.rxl_vc_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxl_vc_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">V/bin</label>
                                            <input
                                                id="rxl_vbin_dx"
                                                v-model="medicalRecord.rxl_vbin_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxl_vbin_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div> 
                                        <div v-if="submitted && (this.$v.medicalRecord.rxl_vn_dx.$error
                                                        || this.$v.medicalRecord.rxl_vc_dx.$error
                                                        || this.$v.medicalRecord.rxl_vbin_dx.$error)"  class="col-12 invalid-feedback d-block">
                                            <span v-if="!this.$v.medicalRecord.rxl_vn_dx.decimal || !this.$v.medicalRecord.rxl_vn_dx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxl_vc_dx.decimal || !this.$v.medicalRecord.rxl_vc_dx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxl_vbin_dx.decimal || !this.$v.medicalRecord.rxl_vbin_dx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                        </div>
                                    </div> 
                                </div>
                                <!-- fine Visius Lon. occhio dx -->
                            </div>
                            <!-- Visius. intermedia -->
                            <div class="row">
                                <!-- Visius Int. occhio sx -->
                                <div class="col-5">       
                                    <div class="row">
                                        <div class="col-4"></div>
                                        <div class="col-2">
                                            <label class="control-label form-label">V/n</label>
                                            <input
                                                id="rxi_vn_sx"
                                                v-model="medicalRecord.rxi_vn_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxi_vn_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2  ">
                                            <label class="control-label form-label">V/c</label>
                                            <input
                                                id="rxi_vc_sx"
                                                v-model="medicalRecord.rxi_vc_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxi_vc_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">V/bin</label>
                                            <input
                                                id="rxi_vbin_sx"
                                                v-model="medicalRecord.rxi_vbin_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxi_vbin_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div v-if="submitted && (this.$v.medicalRecord.rxi_vn_sx.$error
                                                        || this.$v.medicalRecord.rxi_vc_sx.$error
                                                        || this.$v.medicalRecord.rxi_vbin_sx.$error)"  class="col-12 invalid-feedback d-block">
                                            <span v-if="!this.$v.medicalRecord.rxi_vn_sx.decimal || !this.$v.medicalRecord.rxi_vn_sx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxi_vc_sx.decimal || !this.$v.medicalRecord.rxi_vc_sx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxi_vbin_sx.decimal || !this.$v.medicalRecord.rxi_vbin_sx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                        </div>
                                    </div>                          
                                </div>
                                <!-- Fine Visius Int. occhio sx -->
                                <div class="col-2">
                                    <p class="mt-5">Intermedio</p>
                                </div>
                                <!--  Visius Int. occhio dx -->
                                <div class="col-5">
                                    <div class="row">
                                        <div class="col-2">
                                            <label class="control-label form-label">V/n</label>
                                            <input
                                                id="rxi_vn_dx"
                                                v-model="medicalRecord.rxi_vn_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxi_vn_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2  ">
                                            <label class="control-label form-label">V/c</label>
                                            <input
                                                id="rxi_vc_dx"
                                                v-model="medicalRecord.rxi_vc_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxi_vc_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">V/bin</label>
                                            <input
                                                id="rxi_vbin_dx"
                                                v-model="medicalRecord.rxi_vbin_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxi_vbin_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div v-if="submitted && (this.$v.medicalRecord.rxi_vn_dx.$error
                                                        || this.$v.medicalRecord.rxi_vc_dx.$error
                                                        || this.$v.medicalRecord.rxi_vbin_dx.$error)"  class="col-12 invalid-feedback d-block">
                                            <span v-if="!this.$v.medicalRecord.rxi_vn_sx.decimal || !this.$v.medicalRecord.rxi_vn_sx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxi_vc_dx.decimal || !this.$v.medicalRecord.rxi_vc_dx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxi_vbin_dx.decimal || !this.$v.medicalRecord.rxi_vbin_dx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine  Visius Int. occhio dx -->
                            </div>
                            <!-- Visius. vicino -->
                            <div class="row">
                                <!-- Visius Vic. occhio sx -->
                                <div class="col-5">
                                    <div class="row">
                                        <div class="col-4"></div>
                                        <div class="col-2">
                                            <label class="control-label form-label">V/n</label>
                                            <input
                                                id="rxv_vn_sx"
                                                v-model="medicalRecord.rxv_vn_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_vn_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2  ">
                                            <label class="control-label form-label">V/c</label>
                                            <input
                                                id="rxv_vc_sx"
                                                v-model="medicalRecord.rxv_vc_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_vc_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">V/bin</label>
                                            <input
                                                id="rxv_vbin_sx"
                                                v-model="medicalRecord.rxv_vbin_sx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_vbin_sx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div v-if="submitted && (this.$v.medicalRecord.rxv_vn_sx.$error
                                                        || this.$v.medicalRecord.rxv_vc_sx.$error
                                                        || this.$v.medicalRecord.rxv_vbin_sx.$error)"  class="col-12 invalid-feedback d-block">
                                            <span v-if="!this.$v.medicalRecord.rxv_vn_sx.decimal || !this.$v.medicalRecord.rxv_vn_sx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxv_vc_sx.decimal || !this.$v.medicalRecord.rxv_vc_sx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxv_vbin_sx.decimal || !this.$v.medicalRecord.rxv_vbin_sx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine Visius Vic. occhio sx -->
                                <div class="col-2">
                                    <p class="mt-5">Da vicino</p>
                                </div>
                                <!-- Visius Vic. occhio dx -->
                                <div class="col-5">
                                    <div class="row">
                                        <div class="col-2">
                                            <label class="control-label form-label">V/n</label>
                                            <input
                                                id="rxv_vn_dx"
                                                v-model="medicalRecord.rxv_vn_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_vn_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2  ">
                                            <label class="control-label form-label">V/c</label>
                                            <input
                                                id="rxv_vc_dx"
                                                v-model="medicalRecord.rxv_vc_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_vc_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div class="col-2">
                                            <label class="control-label form-label">V/bin</label>
                                            <input
                                                id="rxv_vbin_dx"
                                                v-model="medicalRecord.rxv_vbin_dx"
                                                type="text"
                                                class="form-control"
                                                :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_vbin_dx.$error  }"
                                                placeholder=""
                                            />
                                        </div>
                                        <div v-if="submitted && (this.$v.medicalRecord.rxv_vn_dx.$error
                                                        || this.$v.medicalRecord.rxv_vc_dx.$error
                                                        || this.$v.medicalRecord.rxv_vbin_dx.$error)"  class="col-12 invalid-feedback d-block">
                                            <span v-if="!this.$v.medicalRecord.rxv_vn_dx.decimal || !this.$v.medicalRecord.rxv_vn_dx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxv_vc_dx.decimal || !this.$v.medicalRecord.rxv_vc_dx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                            <span v-if="!this.$v.medicalRecord.rxv_vbin_dx.decimal || !this.$v.medicalRecord.rxv_vbin_dx.between">Sono ammessi solo valori tra 0 e +20<br></span>
                                        </div> 
                                    </div>                        
                                </div>
                                <!-- Fine Visius Vic. occhio dx -->
                            </div>
                            <!-- fine visus vicino -->
                        </b-collapse>
                    </div>
                    <!-- fine Visus -->
                    <!-- Forie -->
                    <div class="row bg-light d-flex justify-content-center">
                        <div class="col-12">
                            <div class="row bg-primary py-2">
                                <div class="col-11">
                                    <h5 class="text-white">
                                        Forie
                                    </h5>
                                </div>
                                <div class="col-1">
                                    <b-button variant="light" v-b-toggle.forie>
                                        Espandi
                                    </b-button>
                                </div>
                            </div>
                        </div>
                        <b-collapse id="forie" visible class="col-8 bg-light py-3">
                            <!-- F. lontano -->
                            <div class="row ">
                                <div class="col-1">
                                </div>
                                <div class="col-3">
                                    <p>Forie da Lontano</p>
                                </div>
                                <div class="col-2">
                                    <input
                                        id=""
                                        v-model="medicalRecord.forieL_dx"
                                        type="text"
                                        class="form-control"
                                        :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_vbin_dx.$error  }"
                                        placeholder=""
                                    />
                                </div>
                                <div class="col-3">
                                    <select class="form-select"
                                        v-model="medicalRecord.forieL_dir"
                                    >
                                        <option value="">Direzione</option>
                                        <option value="ESO">ESO</option>
                                        <option value="EXO">EXO</option>
                                        <option value="IPO">IPO</option>
                                        <option value="IPER">IPER</option>
                                    </select>
                                </div>
                                <div v-if="submitted && (this.$v.medicalRecord.forieL_dx.$error)"  class="col-12 invalid-feedback d-block">
                                    <span v-if="!this.$v.medicalRecord.forieL_dx.number || !this.$v.medicalRecord.forieL_dx.between">Sono ammessi solo valori tra 0 e +50<br></span>
                                </div> 
                    
                            </div>
                            <div class="row  mt-3">
                                <div class="col-1">
                                </div>
                                <div class="col-3">
                                    <p>Forie da Vicino</p>
                                </div>
                                <div class="col-2">
                                    <input
                                        id="province"
                                        v-model="medicalRecord.forieV_dx"
                                        type="text"
                                        class="form-control"
                                        :class="{ 'is-invalid': submitted && this.$v.medicalRecord.rxv_vbin_dx.$error  }"
                                        placeholder=""
                                    />
                                </div>
                                <div class="col-3">
                                    <select class="form-select"
                                        v-model="medicalRecord.forieV_dir"
                                    >
                                        <option value="">Direzione</option>
                                        <option value="ESO">ESO</option>
                                        <option value="EXO">EXO</option>
                                        <option value="IPO">IPO</option>
                                        <option value="IPER">IPER</option>
                                    </select>
                                </div>
                                <div class="col-8"></div>
                                <div v-if="submitted && (this.$v.medicalRecord.forieV_dx.$error)"  class="col-12 invalid-feedback d-block">
                                    <span v-if="!this.$v.medicalRecord.forieV_dx.number || !this.$v.medicalRecord.forieV_dx.between">Sono ammessi solo valori tra 0 e +50<br></span>
                                </div> 
                            </div>
                        </b-collapse>
                    </div>
                    <div class="row ">

                    </div>     
                </div>
                <div class="col-12 py-3">
                    <h5 v-if="this.$props.medicalRecord.updated_at" style="text-align:right">
                        <b>Ultima modifica</b>: <span class="badge bg-primary ms-1">{{this.$props.medicalRecord.updated_at}}</span>
                    </h5>
                </div>
                <div class="p-3 col-12 form-group d-flex justify-content-start">
                    <b-button  type="submit" class="ms-1" variant="primary" 
                    >
                        Salva le modifiche
                    </b-button> &nbsp; &nbsp;
                    <b-button  type="reset" @click.prevent="resetForm()" class="ms-1" variant="outline-primary" 
                        >Annulla</b-button
                    >
                </div>
            </div>
        </form>

    </div>
</template>
