
// import { graphic } from 'echarts/lib/export'


const axisChart_sx = {
   
    
    series: [
        {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            min: 180,
            max: 0,
            splitNumber:6,
            detail: { formatter: ' ' },
            
            axisTick: {
                splitNumber: 5,
                distance: -5,
                length: 20,
                lineStyle: {
                  width: 2,
                  color: '#999'
                }
            },
            axisLine: {
                lineStyle: {
                    color: [[1, '#000']],
                    width: 1
                }
            },
            
            splitLine: {
                length: 140,
                lineStyle: {
                  color: '#999'
                }
            },
            axisLabel: {
                distance: -190,
                color: '#000',
                fontSize: 18
              },
            pointer: {
                offsetCenter: [0, 0],
                icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
                length: '100%',
                itemStyle: {
                    color: '#000'
                }
            },

           
            
            data: [{ value: 50 }]
        }
    ]
};

const axisChart_dx = {
    
    series: [
        {
            type: 'gauge',
            startAngle: 180,
            detail: { formatter: ' ' },
            endAngle: 0,
            min: 180,
            max: 0,
            splitNumber:6,

            axisTick: {
                splitNumber: 5,
                distance: -5,
                length: 20,
                lineStyle: {
                  width: 2,
                  color: '#999'
                }
            },
            axisLine: {
                lineStyle: {
                    color: [[1, '#000']],
                    width: 1
                }
            },
            
            splitLine: {
                length: 140,
                lineStyle: {
                  color: '#999'
                }
            },
            axisLabel: {
                distance: -190,
                color: '#000',
                fontSize: 18
              },
            pointer: {
                offsetCenter: [0, 0],
                icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
                length: '100%',
                itemStyle: {
                    color: '#000'
                }
            },
            
            data: [{ value: 50 }]
        }
    ]
};






export { axisChart_sx,  axisChart_dx };
