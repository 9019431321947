<script>

import ActionReport from "@/components/games/actionReport.vue";


export default {

  components: {
        ActionReport,
    },
  name: 'ResultGame',
  props: {
    gamesReport:{
      type: Array,
      default: () => ([]),
    },
    subject: {
        type: String,
        default: "c",
    }
  },

  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fieldsC: [
        {
          key: "timestamp",
          label: "Data"
        },
        {
          key: "doctor",
          label: "Operatore"
          
        },
        {
          key: "sequence_or_game",
          label: "Sequenza/Gioco"
        },
        {
          key: "avgResults",
          label: "Punteggio"
        },
        
        
        "Azioni",
      ],

      fieldsD: [
        {
          key: "timestamp",
          label: "Data"
        },
        {
          key: "patient",
          label: "Cliente"
          
        },
        {
          key: "sequence_or_game",
          label: "Sequenza/Gioco"
        },
        {
          key: "avgResults",
          label: "Punteggio"
        },
        
        
        "Azioni",
      ],

      fields: [],

      currentAction:{},
      actionModal:false,
    }
  },
  
  computed: {
    rows() {
        return this.gamesReport.length;
    },     

  },

  
  mounted() {
     this.totalRows = this.gamesReport.length;

     this.computedFields(this.subject);

  },

  methods: {
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
        },


    openReport(action){
        this.currentAction=action
        this.actionModal=true
    } ,   

    computedFields(type) {
        if(type==='d')
            
            this.fields=this.fieldsD
        else if(type==='c')
            this.fields=this.fieldsC
    }
  },

  middleware: "authentication",
};
</script>

<template>
    
    <div>        
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4">
                            <!-- page results -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center">
                                    Visualizza  &nbsp; 
                                    <b-form-select
                                    v-model="perPage"
                                    size="sm"
                                    :options="pageOptions"
                                    >
                                    </b-form-select>
                                    &nbsp;risultati
                                </label>
                            </div>
                        </div>
                        <!-- End page results -->
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                            <div
                            id="tickets-table_filter"
                            class="dataTables_filter text-md-end"
                            >
                                <label class="d-inline-flex align-items-center">
                                    <b-form-input
                                    v-model="filter"
                                    type="search"
                                    placeholder="Cerca..."
                                    class="form-control rounded bg-light border-0 ms-2"
                                    ></b-form-input>
                                </label>
                            </div>
                        </div>
                        <!-- End search -->
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                    <b-table
                        class="table table-centered table-nowrap"
                        :items="gamesReport"
                        :fields="fields"
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                    >

                        <template v-slot:cell(sequence_or_game)=data>
                            <i v-if="!data.item.sequence" style=" color:#fb913b;" class="mdi mdi-18px mdi-dice-3"></i> 
                            <i v-if="data.item.sequence" style=" color:#0071DC;" class="mdi mdi-18px mdi-dice-multiple"></i>                             
                            {{data.item.sequence_or_game}}
                        </template>

                        <template v-slot:cell(avgResults)=data>
                            <b-progress :value="data.item.avgResults" :max="100"  height="15px" variant="warning" show-value></b-progress>
                        </template>

                        <template v-slot:cell(azioni)="data">
                            <ul class="list-inline mb-0">
                                <li class="list-inline-item">
                                <a
                                    href="javascript:void(0);"
                                    class="px-2"
                                    v-b-tooltip.hover
                                    title="Dettaglio report"
                                    style="color:#0071DC"
                                    @click="openReport(data.item)"
                                >
                                    <i class="uil uil-database-alt font-size-18"></i>
                                </a>
                                </li>
                            </ul>
                        </template>
                    </b-table>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div
                            class="dataTables_paginate paging_simple_numbers float-end"
                            >
                                <ul class="pagination pagination-rounded mb-0">
                                    <!-- pagination -->
                                    <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                    ></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    </div>
    <b-modal
        v-model="actionModal"
        title="Report"
        size="lg"
        title-class="text-black font-18"
        hide-footer
        body-class="p-3"
    >
        <div slot="modal-title"><i class="mdi mdi-18px mdi-dice-3"></i> Report </div>
        <ActionReport v-bind:action="currentAction" v-bind:subject="this.subject" />
    </b-modal>
</div>
</template>
