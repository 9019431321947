<script>

  
  
export default {
  name: 'ResultGame',


  props: {
    game:{
      type: Object,
      default: () => ({}),
    }
  },

  data() {
    return {
      series: null,
      chartOptions: {
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "22px"
              },
              value: {
                fontSize: "16px"
              },
              total: {
                show: true,
                label: "Punteggio",
                
              }
            }
          }
        },
        labels: ["Risultato"],
        colors: ['#8bbe1b'],
      },
      modesTranslations: {
        'SIT': 'Da seduto',
        'UP': 'In piedi',
        'FAR': 'Da Lontano',
      }
    }
  },
  
  computed: {     
  },

  mounted() {

    this.series=[this.$props.game.score];
    window.dispatchEvent(new Event('resize'))

    
  },

  methods: {
   
    
  },

  

  middleware: "authentication",
};
</script>

<style scoped>
  .results-details{
    font-weight:bold;
    font-size:1.02em;
  }

  .card-title{
    font-size:1.20em;
  }
</style>

<template>

    <div>  

         <b-card no-body style="background-color:#F8F8f8">
          <b-card-body>
            <b-card-title >
              <h2 class="card-title">{{this.game.game}}</h2>
              <hr>
            </b-card-title>
            <b-card-text>
              <div class="row mt-3">
                <div class="col-6 align-self-center">
                  <p><span class="results-details">Modalità: </span>
                     {{ this.modesTranslations[this.game.mode] }}
                  </p>
                  <p><span class="results-details">Livello: </span>{{this.game.level + 1 }}</p>
                  <p><span class="results-details">Punteggio: </span>{{ this.game.score }} / 100 </p>
                </div>
                <div class="col-6">
                   <apexchart
                      class="apex-charts"
                      height="220"
                      type="radialBar"
                      dir="ltr"
                      :series="this.series"
                      :options="this.chartOptions"
                    ></apexchart>
                </div>
              </div>
            </b-card-text>
          </b-card-body>
        </b-card>
        
    </div>
</template>
