<script>

import ScoreGame from "@/components/games/scoreGame.vue";


export default {
  name: 'actionReport',
  components: {ScoreGame},
  props: {
    action:{
      type: Object,
      default: () => ({}),
    },
    subject:{
      type:String,
      default:"c"
    }
  },

  data() {
    return {
      
    }
  },
  
  computed: {       
  },

  mounted() {
    // Set the initial number of items
  },

  methods: {
  },

  middleware: "authentication",
};
</script>

<style scoped>
  .label-info{
    font-size:1.2em;
    background-color: #80D9FF;
  }

  .data-label{
    font-size:1em;
    font-weight:bold;
  }

  .score{
    font-size:1.5em;
  }
  
</style>

<template>
    <div>  

      <div class="row">
      
        <div v-if="this.subject==='c'" class="col-6 text-left ">
          <span class="label-info badge  ms-1">Operatore:</span>
          <span class="data-label"> {{this.action.doctor}}</span>
        </div>
        <div v-if="this.subject==='d'" class="col-6 text-left ">
          <span class="label-info badge  ms-1">Cliente:</span>
          <span class="data-label"> {{this.action.patient}}</span>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <span class="label-info badge ms-1">Data:</span>
          <span class="data-label">&nbsp;{{this.action.timestamp}}</span>
        </div>
        <div v-if="this.action.sequence" class="col-12 mt-2 text-right">
          <span class="label-info badge ms-1">Sequenza:</span>
          <span class="data-label"> {{this.action.sequence}}</span>
        </div>
        
        <div class="col-12 mt-2 d-flex justify-content-center">
          <hr>
            <p class="score">
              <span><i style="color:#f1b44c" class="mdi mdi-36px mdi-medal"></i></span>
              Punteggio: {{this.action.avgResults}}</p>
          <hr>

        </div>
        
        <div class="col-12" v-for="game in action.report" v-bind:key="game.id">
         <!-- <div class="col-12">-->
          <ScoreGame v-bind:game="game" />
        </div>

      </div>
    </div>
</template>
